<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="4" lg="2">
          Type
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3" class="py-0">
          <v-radio-group row v-model="type">
            <v-radio label="Not Invoice" :value="0"></v-radio>
            <v-radio label="Invoiced" :value="1"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="12" md="2" lg="1">
          <v-btn color="primary" @click="find">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-text-field
          flat
          dense
          outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="items" dense :search="search">
        <template v-slot:item.no="{ item }">
          {{ items.findIndex(x => x === item) + 1 }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "query-do",
  data: () => ({
    headers: [
      {
        id: 9,
        text: "DO#",
        value: "doNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 9,
        text: "Old DO#",
        value: "oldDoNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        id: 11,
        text: "DO Status",
        value: "doStatus",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    items: [],
    type: 0,
    search: "",
  }),

  methods: {
    find() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("saleInvoice/queryDO", this.type)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.find();
  },
};
</script>

<style></style>
